import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { MenuItem } from 'components/models/MenuItem';
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
// import { Add } from 'pages/contacts/add/add';
  
  @autoinject()
  export class HeaderTitlePayments {
    message: string;
    @bindable private title: string;
    @bindable private menuItems: Array<MenuItem> = [];
    @bindable private tabs: number;
    @bindable private makePayment: {
      sender: IGenericAccount;
      recipient: IGenericAccount;
      amount: number;
      currency: string;
      note: string;
    }; 
    private navEvent;
    private currentInstruction;
    private currentRouteParam;
    private breadcrumbs = [];
    
    constructor(private router: Router, private ea: EventAggregator, private dialogService: DialogService, private i18n: I18N) { //Initialize the page
      this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        // console.log("current route: " + JSON.stringify(event.instruction.params));
        // console.log(event.result)
        this.currentInstruction = event.instruction 
        this.currentRouteParam = this.currentInstruction.params;
      });
    }

    // addAccount() {
    //   //this.dialogService.open({ viewModel: Add, lock: false })
    //   this.router.navigateToRoute('choose_type_account');
    // }

    active() {} //Get url parameters

    bind() {
      this.updateBreadcrumbs();
    }

    updateBreadcrumbs() {
      const instruction = this.currentInstruction || this.router.currentInstruction;
  
      // Vérifier si instruction est définie avant de l'utiliser
      if (!instruction || !instruction.config) {
        console.error("L'instruction est undefined ou mal définie.");
        return; // Sortir de la fonction pour éviter l'erreur
      }
  
      // Exemple simple de génération de breadcrumbs en fonction de la route
      this.breadcrumbs = [
        { name: this.i18n.tr("components.footer-bottom.home"), route: 'home', isActive: instruction.config.route === 'home' },
        { name: this.i18n.tr("bank.payment.make_payments"), route: 'payments', isActive: instruction.config.route === 'payments'},
        { name: this.i18n.tr("bank.payment.recap.title"), route: 'payments/recap', isActive: instruction.config.route === 'payments/recap'},
        // Ajouter d'autres éléments ici si nécessaire
      ];
    }

    deactivate() {
      this.navEvent.dispose();
    }
  }
    