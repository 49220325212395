import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { bindable } from "aurelia-framework";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import {
  NavigationInstruction,
  PipelineResult,
  Router,
  RouterEvent,
} from "aurelia-router";
import { MenuItem } from "components/models/MenuItem";
// import { Add } from "pages/contacts/add/add";

@autoinject()
export class HeaderTitleProfile {
  message: string;
  @bindable private title: string;
  @bindable private subtitle: string;
  @bindable private menuItems: Array<MenuItem> = [];
  @bindable private isEditing: boolean = false;
  private navEvent;
  private currentInstruction;
  private currentRouteParam;
  private breadcrumbs = [];

  constructor(
    private router: Router,
    private ea: EventAggregator,
    private dialogService: DialogService,
    private i18n:I18N
  ) {
    //Initialize the page
    this.navEvent = this.ea.subscribe(
      RouterEvent.Success,
      (event: {
        instruction: NavigationInstruction;
        result: PipelineResult;
      }) => {
        // console.log("current route: " + JSON.stringify(event.instruction.params));
        // console.log(event.result)
        this.currentInstruction = event.instruction;
        this.currentRouteParam = this.currentInstruction.params;
      }
    );
  }

  active() {
    this.updateBreadcrumbs();
  } //Get url parameters

  bind() {
    this.updateBreadcrumbs();
  }

  updateBreadcrumbs() {
    const instruction = this.currentInstruction || this.router.currentInstruction;

    if (!instruction || !instruction.config) {
      console.error("L'instruction est undefined ou mal définie.");
      return;
    }

    this.breadcrumbs = [
      { name: this.i18n.tr("components.footer-bottom.home"), route: 'home', isActive: instruction.config.route === 'home' },
      { name: this.i18n.tr("profile.profile1"), route: 'profile', isActive: true},
    ];
  }

  deactivate() {
    this.navEvent.dispose();
  }

  changeTitle(newTitle: string) {
    this.title = newTitle; // Mise à jour du titre
  }
}
